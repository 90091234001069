import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { CustomInput } from 'reactstrap';

import Check from 'images/check.svg';

const StyledCheckbox = styled(CustomInput)` 
  padding-left: 24px; 
  .custom-control-label {
    cursor: pointer;
    &:before {
      left: -32px;
      width: 24px;
      height: 24px;
      border: solid 2px ${props => props.theme.main.colors.grey.base};
      border-radius: 0;
      background: transparent;
      top: 0;
    }
    &:after {
      left: -32px;
      width: 24px;
      height: 24px;
      top: 0;
    }
  }
  .custom-control-input {
    &:not(:disabled):active, &:focus, &:hover {
      &~.custom-control-label {
        &:before {
          box-shadow: none;
          background-color: white;
          border-color: ${props => props.theme.main.colors.secondary.base};
        }
      }
    }
    &:checked {
      &~.custom-control-label {
        &:before {
          background-color:  ${props => props.theme.main.colors.secondary.base};
          border-color: ${props => props.theme.main.colors.secondary.base};
        }
        &:after {
          background-image: url(${Check});    
          background-size: 60% 50%;
        }
      }
    }
  }
`;

const Checkbox = ({label, value, name, onChange, disabled = false, inline=false, checked=false}) => {
  const handleChange = () => {
    onChange({target: {name: name, value: value }});
  }
  return (<StyledCheckbox type="checkbox" id={ value ? `${name}-${value}` : `${name}`} name={name} inline={inline} label={label} onChange={handleChange} checked={checked} disabled={disabled} />);
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Checkbox;