import React from 'react'

import Layout from 'components/Layout/Layout';
import Summary from 'components/Summary/Summary';
import SEO from 'components/seo';

const SummaryPage = () => (
  <Layout>
    <SEO title="Loan Summary" />
    <Summary />
  </Layout>
)

export default SummaryPage
