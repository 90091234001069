import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { formatCurrency } from 'utils/format';
import { formatPercent } from 'utils/format';
import Mustache from 'mustache';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { updateState } from 'actions/actions';

import Button from 'components/Common/Form/Button';
import Modal from 'components/Common/Layout/Modal';

const LegalDocument = styled.div`
  ${props => props.theme.main.fonts.body.normal};
  h3 {
    ${props => props.theme.main.fonts.application.h3};
    font-size: 20px;
  }
  h5 {
    ${props => props.theme.main.fonts.application.h3};
  }
`;

const DisclosureStatement = styled(LegalDocument)`
  ol {
    list-style: none;

    &>li {
      position:relative;
      &:before {
        counter-increment: mycounter;
        content: counter(mycounter) ". ";
        position: absolute;
        left: -25px;
      }
      p {
        display: inline;
      }
      & > ol {
        list-style-type: lower-alpha;
        & > li {
          &:before {
            display: none;
          }
        }
      }
    }

    &:first-of-type {
      counter-reset: mycounter
    }
  }  
`;

const TermsDocument = styled(LegalDocument)`
  ol {
    &>li {
      & > ol {
        list-style-type: lower-alpha;
      }
    }
  }
`;

const DownloadLink = styled.a`
  margin: 20px 0px;
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.secondary.dark1};


  &&& {
    margin-right: 0;
  }
  &:hover, &:focus {
    color: ${props => props.theme.main.colors.secondary.dark2};
    text-decoration: underline;
    outline: none;
  }
  &:active {
    color: ${props => props.theme.main.colors.secondary.light1};
    text-decoration: underline;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  min-height: 112px;
`;

export default React.memo(({ isOpen=false, toggle}) => {
  const dispatch = useDispatch();
  const { applicationId, cifId, loanType, loanProducts, isMobile } = useSelector(state => state.reducer);
  const loanCalculator = useSelector(state => state.reducer.loanCalculator);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const currentDate = new Date();

  const loanProduct = loanProducts.find(product => product.loanTypes === loanType);

  const templateVeriables = {
    currentDate: `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`,
    loanAmount: formatCurrency(loanCalculator.loanAmount) || '-',
    allPayments: formatCurrency(loanCalculator.paymentsTotal) || '-',
    term: loanCalculator.term,
    termFrequency: loanCalculator.termUnits === 1 ? 'weeks' : (loanCalculator.term === '1' ? 'month' : 'months'),
    amortization: loanCalculator.term,
    amortizationFrequency: loanCalculator.termUnits === 1 ? 'weeks' : (loanCalculator.term === '1' ? 'month' : 'months'),
    paymentFrequency: (loanCalculator.paymentFrequency === '1') ? 'Weekly' : (loanCalculator.paymentFrequency === '2' ? 'Bi-weekly' : 'Monthly'),
    monthlyPayment: formatCurrency(loanCalculator.paymentAmount) || '-',
    APR: formatPercent(loanCalculator.apr) || '-',
    interestRate: formatPercent(loanCalculator.interestRate) || '-',
    costOfCredit: formatCurrency(loanCalculator.totalCostOfCredit) || '-',
    primeLendingRate: loanProduct ? formatPercent(loanProduct.vancityPrimeRate) : '-'
  } 
  
  const result = useStaticQuery(graphql`
    query {
      allContentfulLegalDocument {
        nodes {
          loanType
          disclosureStatement  {
            json
          }
          termsAndConditions {
            json
          }
        }
      }
    }
  `);

  const options = {
    renderText: text => Mustache.render(text, templateVeriables).split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const submit = () => {
    dispatch(updateState({
      termsConditionsAccepted: true
    }));
    toggle();
  }

  const onScroll = (e) => {    
    if(Math.ceil(e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight) {
      setScrolledToBottom(true);
    }        
  }

  const contentfulLegalDocument = result.allContentfulLegalDocument.nodes.find((legalDocument) => legalDocument.loanType === loanType);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header">
        <h5 className="modal-title">
          Loan Disclosure Statement and Terms & Conditions
        </h5>
        <p>Scroll to the bottom to complete this step.</p>
      </div>
      <ModalBody onScroll={onScroll} tabIndex="0">
        <DisclosureStatement className="disclosure-statement">{contentfulLegalDocument ? documentToReactComponents(contentfulLegalDocument.disclosureStatement.json, options) : ''}</DisclosureStatement>
        <TermsDocument>{contentfulLegalDocument ? documentToReactComponents(contentfulLegalDocument.termsAndConditions.json, options) : ''}</TermsDocument>   
      </ModalBody>
      <StyledModalFooter>
        {isMobile ? 
          <>
            <form>
              <input type="hidden" name="cifId" value={cifId} />
              <input type="hidden" name="applicationId" value={applicationId} />
            </form> 
            <DownloadLink href={`${process.env.GATSBY_BACKEND_URL}/DocumentGenerate`} target="_blank">Download</DownloadLink>
          </> :
          <DownloadLink href={`${process.env.GATSBY_BACKEND_URL}/DocumentGenerate?cifId=${cifId}&applicationId=${applicationId}`} target="_blank" data-testid="button-download">Download</DownloadLink> 
        }
        <Button onClick={submit} disabled={!scrolledToBottom} name="button-done">Done</Button>
      </StyledModalFooter>
    </Modal>
  );
});