import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import { fundLoan, updateState } from 'actions/actions';
import { formatCurrency, formatPercent } from 'utils/format';
import styled from 'styled-components';
import { format } from 'date-fns';
import breakpoint from 'styled-components-breakpoint';

import DocumentOverlay from './DocumentOverlay';
import Header from 'components/Common/Layout/Header';
import Navigation from 'components/Common/Layout/Navigation';
import Form from 'components/Common/Form/Form';
import Checkbox from 'components/Common/Form/Checkbox';
import StaticFieldRow from 'components/Common/Form/StaticFieldRow';
import Disclaimer from 'components/Common/Layout/Disclaimer';
import Cancel from 'components/Common/Cancel/Cancel';

const StyledFormGroup = styled(FormGroup)` 
  padding-left: 45px;
  margin-bottom: 18px;
  .form-check-label {
    ${props => props.theme.main.fonts.body.normal};
    color: black;
    .custom-checkbox {
      display: inline-block;
      margin-left: -36px;
    }
    u {
      cursor: pointer;
      color: ${props => props.theme.main.colors.secondary.dark1};
      &:hover {
        text-decoration: none;
      }
    }
    a {
      color: black;
      text-decoration: underline;
    }
  }
`;

const SubHeading = styled.h2`
  ${props => props.theme.main.fonts.application.h2};
  margin-top: 42px;
  margin-bottom: 24px;
`;

const Paragraph = styled.p`
  &&& {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.grey.base};
    margin-bottom: 20px;
    a {
      color: ${props => props.theme.main.colors.secondary.dark1};
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
const Divider = styled.hr`
  border-color: ${props => props.theme.main.colors.neutral.line};
  border-width: 1px;
  border-style: solid;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  ${breakpoint('md')` 
    margin-top: 24px;
    margin-bottom: 16px;
  `}
`;

const LoanSummarySection = styled.div`
  div:first-of-type {
    padding-top: 0;
  }
`;

const StyledNavigation = styled(Navigation)`
  margin-top: 32px;
`;

export default React.memo(() => {
  const dispatch = useDispatch();
  const loanCalculator = useSelector(state => state.reducer.loanCalculator);
  const { applicationId, cifId, termsConditionsAccepted, actingMyBehalfAccepted, understandInsurance, loanPurpose, personalInfo, loanType, accessToken, isMobile } = useSelector(state => state.reducer);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [state, setState] = useState({
    termsConditionsAccepted: termsConditionsAccepted,
    actingMyBehalfAccepted: actingMyBehalfAccepted,
    understandInsurance: understandInsurance
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(name === 'termsConditionsAccepted' && state[name] === false) {
      setDocumentOpen(true);
    }
    else {
      dispatch(updateState({
        [name]: !state[name]
      }));
      setState(prevState => ({ ...prevState, [name]: !state[name] }));
    }
  }

  useEffect(()=> {
    setState(prevState => ({ ...prevState, termsConditionsAccepted: termsConditionsAccepted, actingMyBehalfAccepted: actingMyBehalfAccepted, understandInsurance: understandInsurance }));
    
  }, [termsConditionsAccepted, actingMyBehalfAccepted, understandInsurance]);

  const canContinue = termsConditionsAccepted && actingMyBehalfAccepted && (loanType === 'OnlineFairAndFastLoan' || understandInsurance);

  const submit = (e) => {
    e.preventDefault();
    if(canContinue) {
      dispatch(fundLoan({
        cifId: cifId,
        applicationId: applicationId,
        additionalInfo: {
          loanPurpose: loanPurpose,
          ...personalInfo.employment
        }
      }, accessToken, isMobile));  
    }
  }

  let paymentFrequencyLabel = '-'

  if(loanCalculator.paymentFrequency === 'BiWeekly') {
    paymentFrequencyLabel = 'Bi-weekly';
  }
  else if(loanCalculator.paymentFrequency) {
    paymentFrequencyLabel = loanCalculator.paymentFrequency;
  }

  return (
    <Form>
      <LoanSummarySection>
        <Header>Loan summary</Header>
        <StaticFieldRow label="Loan amount" value={formatCurrency(loanCalculator.loanAmount)} name="loanAmount" />
        <StaticFieldRow label={<>Interest <span data-testid="interestRate">{formatPercent(loanCalculator.interestRate)}</span></>} value={formatCurrency(loanCalculator.totalCostOfCredit)} name="totalCostOfCredit" footnoteText="1" footnoteTo="footnote-1" />
        <StaticFieldRow label="Loan + interest" value={formatCurrency(loanCalculator.paymentsTotal)} name="paymentsTotal" />
        <Divider />
      </LoanSummarySection>
      <LoanSummarySection>
        <StaticFieldRow label={`${paymentFrequencyLabel} payment`} value={formatCurrency(loanCalculator.paymentAmount)} name="monthlyPayments" />
        <StaticFieldRow label="Loan length" value={`${loanCalculator.term} ${loanCalculator.termUnits.toLowerCase()}`} name="loanLength" />
        <StaticFieldRow label="First payment" value={loanCalculator.firstPaymentDate ? format(new Date(loanCalculator.firstPaymentDate), 'EEEE, MMMM d yyyy') : ''} name="firstPayment" footnoteText="2" footnoteTo="footnote-2" />
        <StaticFieldRow label="Last payment" value={loanCalculator.maturityDate ?  format(new Date(loanCalculator.maturityDate), 'EEEE, MMMM d yyyy') : ''} name="lastPayment" />
      </LoanSummarySection>

      {loanType === 'OnlineTermLoan' ?
        <div>
          <SubHeading main={false}>Loan Insurance</SubHeading>
          <Paragraph><a href={`${process.env.GATSBY_VANCITY_URL}/Loans/Insurance/`} target="_blank">Loan insurance</a> is available for Vancity lending products. Please call the Member Service Centre or visit a branch to determine if you are eligible.</Paragraph>
          <StyledFormGroup check>
            <Label check>          
              <Checkbox name="understandInsurance" onChange={handleChange} checked={understandInsurance} />{' '}
              I acknowledge that if I want to insure my loan, I need to call the Member Service Centre or visit a Vancity branch.
            </Label>
          </StyledFormGroup>
        </div> : null }
      
      <SubHeading main={false}>Terms &amp; conditions</SubHeading>
      <DocumentOverlay isOpen={documentOpen} toggle={()=> setDocumentOpen(!documentOpen)}  />
      <Paragraph>We want you to understand how the loan works. Please read and agree to these terms before you submit the application.</Paragraph>
      <StyledFormGroup check>
        <Label check>          
          <Checkbox name="termsConditionsAccepted" onChange={handleChange} checked={termsConditionsAccepted} />{' '}
          I agree to the <u>Loan Disclosure Statement and Terms & Conditions</u>.
        </Label>
      </StyledFormGroup>
      <StyledFormGroup check>
        <Label check>          
          <Checkbox name="actingMyBehalfAccepted" onChange={handleChange} checked={actingMyBehalfAccepted} />{' '}
          I confirm that I am acting on behalf of myself and not on behalf of any third party.
        </Label>
      </StyledFormGroup>
      <Cancel confirmOpen={confirmOpen} setConfirmOpen={setConfirmOpen} />
      <StyledNavigation buttonLabel="Finish" onClickForward={submit} disabled={!canContinue} displayCloseIcon={true} backLabel="Cancel loan application" onClickBack={() => setConfirmOpen(true)} />
      <Disclaimer>
        <p id="footnote-1"><sup>1</sup> The annual percentage rate (APR) is compounded monthly, not in advance. APR assumes no fees or charges apply. If additional fees and/or charges apply, the total Cost of Credit and APR would increase. This product, its features and advertised interest rates may change at any time and may be withdrawn by Vancity at any time for any reason without prior notice to the member.</p>
        <p id="footnote-2"><sup>2</sup> Dates are calculated based on Pacific Standard Time (PST).</p>
      </Disclaimer>
    </Form>);
});